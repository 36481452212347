import React from 'react'

export default function Blog() {
  return (
    <div className="blog">
      <h1>Blog</h1>
      <hr/>
        <p>
          Welcome to my personal website! I'm excited to share everything I've been working on here. For any
          questions or comments, please feel free to contact me. Blog posts coming soon! 
        </p>
    </div>
  )
}
